import React, { useEffect, useRef, useState } from "react";
import { TextArea, TextAreaProps } from "semantic-ui-react";
import styled from "styled-components";

const EditableDivWithPinnedText = styled.div<{ text: string }>`
  ::before {
    content: "${(props) => props.text}";
    color: grey;
  }
  padding: 11px 14px;
  position: relative;
  min-height: 100px;
`;

const HideTextareaResizer = styled.div<{ focused?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: white;
  border-right: ${(props) =>
    props.focused
      ? "1px solid rgb(0, 95, 204)"
      : "1px solid rgba(34, 36, 38, 0.15)"};
  border-bottom: ${(props) =>
    props.focused
      ? "1.5px solid rgb(0, 95, 204)"
      : "1px solid rgba(34, 36, 38, 0.15)"};
  border-radius: 0 0 4px 0;
  z-index: 1;
`;

const RemainingCharacters = styled.div<{ exceeded?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 10px;
  color: ${(props) => (props.exceeded ? "red" : "grey")};
`;

export const PtdCustomTextArea: React.FC<
  Omit<TextAreaProps, "value"> & {
    value?: string;
    text?: string;
    textHover?: string;
    maxCharacters?: number;
  }
> = (props) => {
  const [height, setHeight] = useState("auto");
  const [inputValue, setInputValue] = useState(props.value);
  const [focused, setFocused] = useState(false);
  const editableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editableRef.current) {
      editableRef.current.innerHTML =
        ((props.value as string) ?? "").length > 0
          ? "&nbsp;" + `${props.value ?? ""}`
          : "";
      setHeight(`${editableRef.current.clientHeight}px`);
    }

    const resizeObserver = new ResizeObserver(() => {
      if (editableRef.current) {
        setHeight(`${editableRef.current.clientHeight}px`);
      }
    });
    resizeObserver.observe(editableRef.current as Element);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div style={{ position: "relative", height: "fit-content" }}>
      <div style={{ position: "absolute", height: "100%", width: "100%" }}>
        <EditableDivWithPinnedText
          ref={editableRef}
          text={props.text ?? ""}
          contentEditable
          onInput={(e) => {
            setHeight(`${(e.target as HTMLDivElement).clientHeight}px`);
            setInputValue((e.target as HTMLDivElement).innerText);
            props.onChange?.(
              {
                target: {
                  value: (e.target as HTMLDivElement).innerText.trim(),
                },
              } as any,
              {}
            );
          }}
          onFocus={() => {
            setFocused(true);
            if (props.text && editableRef.current?.innerText.length === 0) {
              editableRef.current.innerHTML = "&nbsp;";
            }
          }}
          onBlur={() => setFocused(false)}
        />
        {props.maxCharacters && (
          <RemainingCharacters
            exceeded={
              inputValue && props.maxCharacters
                ? inputValue.trim().length > props.maxCharacters
                : false
            }
          >{`${(inputValue?.trim() ?? "").length}/${
            props.maxCharacters
          }`}</RemainingCharacters>
        )}
        <HideTextareaResizer focused={focused} />
      </div>
      <TextArea
        name={props.name}
        onChange={props.onChange}
        value={inputValue}
        style={{ color: "transparent", height }}
        maxLength={props.maxCharacters}
        readOnly
      />
    </div>
  );
};
